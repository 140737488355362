import { createMetaObject } from '@/services/helpers/MetaHelper'

const pageMixin = {
  props: {
    guard: {
      type: [Function, undefined],
      required: false
    }
  },
  data () {
    return {
      readyToRender: false,
      metaData: {}
    }
  },
  computed: {
    metaOnCreate () {
      return true
    },
    title () {
      console.warn('Please change the default implementation of the page title in the new component')
      return 'Default title'
    },
    meta () {
      return {
        title: this.title
      }
    }
  },
  methods: {
    afterEnter () {
      if (typeof this.guard !== 'function') return
      this.guard()
    }
  },
  async created () {
    if (this.metaOnCreate) {
      this.metaData = await createMetaObject(this.meta)
    }
  },
  mounted () {
    setTimeout(() => { this.readyToRender = true }, 0)
  },
  metaInfo() {
    return this.metaData
  },
  watch: {
    async title () {
      this.metaData = await createMetaObject(this.meta)
    }
  }
}

export default pageMixin
