const cssMixin = {
  data () {
    return {
      css: null
    }
  },
  computed: {
    reference () {
      return this.pageId ? `page-${this.pageId}` : `block-${this.identifier}`
    }
  },
  metaInfo () {
    return this.css ? { style: [ { cssText: this.css, type: 'text/css', reference: this.reference } ] } : {}
  }
}

export default cssMixin
