<template>
  <div id="filter" class="container mt-2" item-list-id="filter">
    <div class="row">
      <div class="col-12 col-lg-3" v-if="$root.gridBreakpoint >= $gridSizes.lg">
        <TheFilterSideBar/>
      </div>
      <div class="col-12 col-lg-9">
        <div class="row sticky-top bg-light-beige z-3">
          <div class="col-12">
            <TheFilterTopBar class="filter-bar mb-2"
                             v-if="$root.gridBreakpoint >= $gridSizes.lg"
                             :index-from="indexFrom"
                             :index-to="indexTo"
            />
            <TheFilterMobile v-else/>
            <TheFilterAppliedOptionsBar v-if="!error"
                                        class="attribute-options d-flex pl-3 pl-md-0 pb-3 pb-md-1"/>
          </div>
        </div>
        <transition name="fade">
          <HeroTile v-if="heroData.product_id && heroData.campaign_id"
                    v-show="!loading"
                    :product-id="heroData.product_id" :campaign-id="heroData.campaign_id"
                    :catch-phrase="heroData.catch_phrase" class="mt-3"/>
        </transition>
        <LoadingOverlay width="70" height="70" :use-background="false" class="position-relative my-3"
                        v-show="loading && !error"/>
        <div v-if="hasResults">
          <transition name="fade">
            <div id="product-list" v-if="totalPages" v-show="!loading">
              <TheFilterProductList
                :offers-visible="totalPages === currentPage"
                @changeSupplier="onPageChange(1)"
              />
            </div>
          </transition>
          <div v-if="totalPages > 1" :loading="loading">
            <Pagination
              class="container bg-white py-2 border-b"
              :current-page="currentPage"
              :total-pages="totalPages"
              @pageChanged="changePage"
            />
          </div>
        </div>
        <div v-else-if="!loading">
          <transition name="fade">
            <NoResults class="mt-md-3"/>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PRODUCTS_PER_PAGE } from '@/constants/CategoryPageConstants'
import { mapActions, mapState } from 'pinia'
import { useSearchConfig } from '@/stores/searchConfig'

const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')
const TheFilterAppliedOptionsBar = () => import('@/components/category/molecules/TheFilterAppliedOptionsBar')
const TheFilterProductList = () => import('@/components/category/molecules/TheFilterProductList')
const TheFilterSideBar = () => import('@/components/category/organisms/TheFilterSideBar')
const TheFilterTopBar = () => import('@/components/category/molecules/TheFilterTopBar')
const TheFilterMobile = () => import('@/components/category/organisms/TheFilterMobile')
const Pagination = () => import('@/components/basic/molecules/Pagination')
const NoResults = () => import('@/components/category/molecules/NoResults')
const HeroTile = () => import('@/components/category/molecules/HeroTile')

const SUPPLIER_VICAMPO = 'VICAMPO'
const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: MessageHelper },
      { default: DomHelper }
    ] = await Promise.all([
      await import('@/services/helpers/MessageHelper'),
      await import('@/services/helpers/DomHelper'),
    ])
    cache = { MessageHelper, DomHelper }
    return cache
  }
})()

export default {
  name: 'TheFilter',
  components: {
    NoResults,
    LoadingOverlay,
    Pagination,
    TheFilterMobile,
    TheFilterSideBar,
    TheFilterProductList,
    TheFilterAppliedOptionsBar,
    HeroTile,
    TheFilterTopBar
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapState(useSearchConfig, {
      loading: 'categoryIsLoading',
      category: 'category',
      config: 'getConfig',
      products: 'getProducts',
    }),
    error() {
      return this.config.error
    },
    hasSupplierVicampo() {
      const { supplier } = this.config.filters
      if (!supplier) return true
      return Object.keys(supplier).includes((SUPPLIER_VICAMPO))
    },
    heroData() {
      const { supplier: _, ...rest } = this.config.filters
      const { hero_data: data } = this.category

      if (this.isSearch) {
        return {}
      }

      if (!this.hasSupplierVicampo || Object.keys(rest).length || !(data?.product_id && data?.campaign_id)) {
        return {}
      }

      return { product_id: data.product_id, campaign_id: data.campaign_id, catch_phrase: data.catch_phrase }
    },
    totalPages() {
      return this.config.totalPages
    },
    indexFrom() {
      if (this.indexTo > PRODUCTS_PER_PAGE) {
        return (this.currentPage - 1) * PRODUCTS_PER_PAGE + 1
      }
      return this.indexTo ? 1 : 0
    },
    indexTo() {
      return Math.min(this.currentPage * PRODUCTS_PER_PAGE, this.config.totalCount)
    },
    hasResults() {
      return this.config.totalCount > 0
    },
    searchTerm() {
      return this.config.query?.original
    },
    hasActiveAttributes() {
      return Object.keys(this.config.filters || {}).length > 0 || !!this.searchTerm
    },
    isSearch () {
      return !!this.$route.query.search
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchCategory', 'updateCategory', 'applyFilter']),
    async errorCallback(e) {
      console.error(e)
      const { MessageHelper } = await _getModules()
      await MessageHelper.addErrorMessage('unknown_error')
    },
    async onPageChange(value) {
      try {
        await this.changePage(value)
        await this.handleUpdate()
      } catch (e) {
        await this.errorCallback(e)
      }
    },
    async handleUpdate() {
      await this.fetchCategory()

      const { DomHelper } = await _getModules()
      DomHelper.scrollTo('#filter')
    },
    async changePage (value) {
      if (+value === this.currentPage) return

      this.currentPage = +value
      this.updateCategory({ group: 'pagination_page', value, immediate: false })
      this.applyFilter()
    }
  },
  async created() {
    this.currentPage = +this.$route.query.pagination_page || 1
  },
  watch: {
    async $route(route) {
      const { query: { pagination_page: page = 1 } } = route
      await this.onPageChange(page)
    }
    }
}
</script>
<style lang="scss" scoped>

.attribute-options {
    min-height: 60px;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 0 -15px;
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
      overflow-x: initial;
      flex-wrap: wrap;
      margin: 0;
    }
  }

  .loading {
    min-height: 70px;
  }

  .fade-enter-active {
    transition: opacity 1s $transition-timing-cb-vicampo .5s;
  }

  .fade-leave-active {
    transition: opacity .5s $transition-timing-cb-vicampo;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

</style>
