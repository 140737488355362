<template>
  <header id="header-wrapper" class="mb-2">
    <div class="content d-flex flex-column align-items-center">
      <div id="checkout-header" class="row justify-content-center py-4 text-center">
        <VicampoLink href="/" itemscope itemtype="https://schema.org/Brand" class="col-8 col-md-5 mb-3 mb-md-0">
          <img src="@/assets/images/logo-main.svg" class="w-75 h-75" alt="vicampo">
        </VicampoLink>
        <div class="col-12 col-md-6">
          <TheCheckoutStepper :initialStep="currentStep" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TheCheckoutStepper from '@/components/checkout/molecules/TheCheckoutStepper.vue'
const VicampoLink = () => import('@/components/basic/atoms/VicampoLink')

export default {
  name: 'TheCheckoutHeader',
  props: ['currentStep'],
  components: { 
    TheCheckoutStepper, 
    VicampoLink 
  },
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  background: $white;
  min-height: 130px;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    min-height: 98px;
  }
}

#header-wrapper {
  margin-bottom: 5px;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-bottom: inherit;
  }
}

#checkout-header > div, #checkout-header > form {
  display: flex;
  align-items: center;
  height: 75px;
}
</style>
