<template>
  <header id="header-wrapper">
    <CmsBlock identifier="fe_app_maintenance" disable-animation hide-on-error/>
    <CmsBlock identifier="before_header_v2" disable-animation hide-on-error/>
    <div class="content">
      <UspBar/>
      <div class="container c-gray f-gray">
        <div id="main-header" class="row">
          <VicampoLink href="/" itemscope itemtype="https://schema.org/Brand" class="col-5 col-md-3">
            <img src="@/assets/images/logo-main.svg" class="w-100 h-100" alt="vicampo">
          </VicampoLink>
          <div class="col-3 col-md-2 col-lg-3 d-none d-md-flex justify-content-around align-items-center">
            <div class="d-none d-xl-inline-flex">
              <SvgWrapper use="icon_svg_delivery_free" dimensions="40" class="flex-shrink-0"/>
              <div>
                <div class="font-size-s font-weight-bold ml-3 text-uppercase">{{ translate('free_shipping') }}</div>
                <div class="font-size-xs font-libre font-italic d-none d-sm-block ml-3">{{ translate('shipping_subline') }}</div>
              </div>
            </div>
            <TheTrustedShops />
          </div>
          <SearchForm class="col-7 col-md-3"/>
          <CustomerNavigation class="col-12 col-md-4 col-lg-3" @openMenu="openMenu"/>
        </div>
      </div>
      <HeaderNavigation @closeMenu="closeMenu" @showOverlay="showOverlay" @hideOverlay="hideOverlay" :open="open"/>
    </div>
  </header>
</template>

<script>
import SearchForm from '@/components/basic/atoms/TheSearchForm'
import UspBar from '@/components/basic/atoms/UspBar'
import HeaderNavigation from '@/components/basic/molecules/HeaderNavigation'
import OverlayHelper from '@/services/helpers/OverlayHelper'
import DomHelper from '@/services/helpers/DomHelper'
import SvgWrapper from '@/components/basic/atoms/SvgWrapper.vue'

const TheTrustedShops = () => import('@/components/opc/atoms/TheTrustedShops.vue')
const VicampoLink = () => import('@/components/basic/atoms/VicampoLink')
const CustomerNavigation = () => import('@/components/basic/molecules/CustomerNavigation')
const CmsBlock = () => import('@/components/basic/atoms/CmsBlock')

export default {
  name: 'Header',
  components: { TheTrustedShops, SvgWrapper, VicampoLink, CustomerNavigation, HeaderNavigation, UspBar, SearchForm, CmsBlock },
  data () {
    return {
      open: false
    }
  },
  methods: {
    openMenu () {
      this.open = !this.open
      DomHelper.disableBodyScroll()
    },
    closeMenu () {
      this.open = false
      DomHelper.enableBodyScroll()
    },
    showOverlay () {
      OverlayHelper.highlight(this.$el)
    },
    hideOverlay () {
      OverlayHelper.highlight(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  background: $light-beige;
  min-height: 175px;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    min-height: 138px;
  }
}

#header-wrapper {
  margin-bottom: 15px;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-bottom: inherit;
  }
}
#main-header > div, #main-header > form {
  display: flex;
  align-items: center;
  height: 75px;
}
</style>
