const promotionContentMixin = {
  data () {
    return {
      /*
      * !!! DISCLAIMER !!!
      * ga4Data root object should not be mutated, only the child props to keep the reference passing
      *
      * eg.: this.gat4Data.child = value
      */
      ga4Data: {},
      promotionTrackings: [],
      contentTrackings: []
    }
  },
  methods: {
    async initPromotionAndContentTracking () {
      this.kill()

      await this.$nextTick()
      // run in webAPI
      setTimeout(() => {
        // prevent failing on <!----> when there is no rendered content
        if (!this.$el.tagName) return

        this.initPromotionTracking()
        this.initContentTracking()
      }, 100)
    },
    async initPromotionTracking () {
      const { Promotion, PROMOTION_ID } = await import('@/services/analytics/Promotion')
      this.promotionTrackings = _getNodes(this.$el, `[data-${PROMOTION_ID}]`).map(el => new Promotion(el, this.ga4Data))
    },
    async initContentTracking () {
      const { Content, CONTENT_ID } = await import('@/services/analytics/Content')
      this.contentTrackings = _getNodes(this.$el, `[data-${CONTENT_ID}]`).map(el => new Content(el, this.ga4Data))
    },
    kill () {
      this.promotionTrackings.forEach(e => { e.destroy() })
      this.contentTrackings.forEach(e => { e.destroy() })
    }
  },
  beforeDestroy () {
    this.kill()
  }
}

const _getNodes = (template, selector) => [...template.querySelectorAll(selector)]

export default promotionContentMixin
