<template>
  <div>
    <h1>Payment Section</h1>
  </div>
</template>

<script>
export default {
  name: 'TheCheckoutPayment'
}
</script>