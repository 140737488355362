const jsMixin = {
  data () {
    return {
      js: null
    }
  },
  watch: {
    async js () {
      await this.$nextTick()
      // eslint-disable-next-line no-new-func
      Function(this.js)()
    }
  }
}

export default jsMixin
