<template>
  <div>
    <div class="bg-beige align-center">
      <div v-if="$root.gridBreakpoint > $gridSizes.sm" id="main-nav"
           class="container justify-content-center d-md-block mobile">
        <CmsBlock class="row align-items-center justify-content-center h-100" identifier="fe_app_navigation"
                  @loaded="loaded" disable-animation/>
      </div>
      <VNavigationDrawer v-else :value="open" width="100%" id="main-nav"
                         class="container justify-content-center d-md-block mobile">
        <div class="close-menu d-md-none d-flex" @click="$emit('closeMenu')">
          <SvgWrapper href="icon_svg_close" use="icon_svg_close" dimensions="30"/>
        </div>
        <CmsBlock class="row align-items-center justify-content-center h-100" identifier="fe_app_navigation"
                  @loaded="loaded" disable-animation/>
      </VNavigationDrawer>
    </div>
  </div>
</template>

<script>
import { HTML_EVENT } from '@/constants/GlobalConstants'
import UtilityHelper from '@/services/helpers/UtilityHelper'

const SvgWrapper = () => import('@/components/basic/atoms/SvgWrapper')
const CmsBlock = () => import('@/components/basic/atoms/CmsBlock')

const SUPPLIER = '?supplier=VICAMPO'
export default {
  name: 'HeaderNavigation',
  components: { SvgWrapper, CmsBlock },
  props: {
    open: Boolean
  },
  methods: {
    loaded() {
      this.dropdowns()
      const links = this.$el.querySelectorAll('.alle-weine a.dropdown-toggle')
      if (links.length > 0 && links[0]) {
        links[0].setAttribute('href', links[0].getAttribute('href') + SUPPLIER)
      }
    },
    dropdowns() {
      const dropdowns = this.$el.getElementsByClassName('dropdown')
      for (const dropdown of dropdowns) {
        dropdown.addEventListener(HTML_EVENT.MOUSE_ENTER, () => {
          this.$emit('showOverlay')
        })
        dropdown.addEventListener(HTML_EVENT.MOUSE_LEAVE, () => {
          this.$emit('hideOverlay')
        })
        if (UtilityHelper.hasTouch() && this.$root.gridBreakpoint > this.$gridSizes.md) {
          dropdown.addEventListener(HTML_EVENT.CLICK, (event) => {
            event.preventDefault()
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep#main-nav {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  margin-bottom: 0;
  background-color: $chalkboard;
  color: $white;
  font-size: $font-size-xl;
  transition: none;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    color: $graphit;
    position: relative;
    background-color: inherit;
    z-index: inherit;
  }

  .close-menu {
    fill: $white;
    margin: 15px;
    right: 0;
    height: 30px;
    position: absolute;
    cursor: pointer;
    z-index: 10001;
  }

  .row .block-content > ul {
    display: inline-flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
      flex-flow: row;
      align-items: flex-start;
    }

    >li {
      width: 100%;
      text-align: center;
      position: static;
      display: flex;
      justify-content: center;

      &:hover,
      &:active,
      &:focus {
        color: $graphit;
      }

      &.show,
      &:hover {
        background-color: $white;
      }

      @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        width: auto;
        text-align: inherit;

        &:hover,
        &:active,
        &:focus {
          color: inherit;
        }
      }

      > a {
        display: flex;
        align-items: center;
        color: inherit;
        margin: 0;
        padding: 0 15px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        fill: $graphit;
        font-size: $font-size-xl;
        height: 48px;
        line-height: 48px;

        &:after {
          content: none;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
          font-size: inherit;
          line-height: normal;
          height: 38px;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
          padding: 0 20px;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
          &:after {
            content: '';
          }
        }
      }

      .dropdown-menu {
        padding: 2em;
        background: $white;
        font-weight: normal;
        border: none;
        border-bottom: 2px solid $beige;
        font-size: $font-size-s;
        letter-spacing: 1px;
        margin: 0;
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
          font-size: $font-size-s;
        }

        &.show {
          display: flex;
        }

        a {
          white-space: normal;
          color: inherit;
          cursor: pointer;
          pointer-events: all;
          position: relative;

          &:hover {
            color: $graphit;
          }

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0;
            background-color: $graphit;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
          }

          &:hover::before {
            visibility: visible;
            transform: scaleX(1);
          }

          &:before {
            height: 1px;
          }
        }

        ul {
          list-style-type: none;
        }

      }

      li.headline {
        margin: 0 10px 10px 10px;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 2px solid $light-ochre;
        padding-bottom: 2px;

        a {
          margin-left: 0 !important;
          margin-right: 0 !important;
          pointer-events: none;

          &:before {
            height: 0;
          }
        }
      }
    }
  }
}
</style>
