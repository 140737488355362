<template>
  <VStepper v-model="step" id="checkout-stepper" alt-labels elevation="0" @change="handleStepChange">
    <VStepperHeader>
      <VStepperStep step="1" class="text-uppercase" editable>
        {{ translate('checkout_login') }}
      </VStepperStep>
      <VDivider id="checkout-divider" />
      <VStepperStep step="2" class="text-uppercase" editable>
        {{ translate('checkout_address') }}
      </VStepperStep>
      <VDivider />
      <VStepperStep step="3" class="text-uppercase" editable>
        {{ translate('checkout_payment') }}
      </VStepperStep>
      <VDivider />
      <VStepperStep step="4" class="text-uppercase" editable>
        {{ translate('checkout_delivery') }}
      </VStepperStep>
      <VDivider />
      <VStepperStep step="5" class="text-uppercase" editable>
        {{ translate('checkout_review') }}
      </VStepperStep>
    </VStepperHeader>
  </VStepper>
</template>

<script>
export default {
  props: {
    initialStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      step: this.initialStep
    }
  },
  mounted() {
    this.step = this.getStepFromRoute(this.$router.currentRoute.path)
  },
  methods: {
    handleStepChange(newStep) {
      const targetRoute = `/checkout/${this.getStepRoute(Number(newStep))}`
      const currentRoute = this.$router.currentRoute.path
      if (currentRoute !== targetRoute) {
        this.$router.push(targetRoute)
          .catch(err => {
            // Ignore NavigationDuplicated error
            if (err.name !== 'NavigationDuplicated') {
              console.error(err)
            }
          })
      }
    },
    getStepRoute(step) {
      switch (step) {
        case 1: return 'login'
        case 2: return 'address'
        case 3: return 'payment'
        case 4: return 'delivery'
        case 5: return 'review'
        default: return 'login'
      }
    },
    getStepFromRoute(path) {
      switch (path) {
        case '/checkout/login': return 1
        case '/checkout/address': return 2
        case '/checkout/payment': return 3
        case '/checkout/delivery': return 4
        case '/checkout/review': return 5
        default: return 1
      }
    }
  },
  watch: {
    initialStep(newStep) {
      this.step = newStep
    }
  }
}
</script>

<style lang="scss" scoped>
#checkout-divider ::v-deep .v-divider {
  width: 200px
}
#checkout-stepper ::v-deep .v-stepper__label {
  display: flex;
  font-size: $font-size-s;
  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: $font-size-l
  }
}
#checkout-stepper ::v-deep .v-stepper__step.v-stepper__step--editable:hover:not(.primary) {
  background: transparent;
  .v-stepper__step__step {
    background: rgba(0, 0, 0, 0.38)
  }
  .v-stepper__step__step:not(.primary) + .v-stepper__label {
    text-shadow: none
  }
}
#checkout-stepper ::v-deep .v-stepper__step {
  flex-basis: 80px
}
</style>
